import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
//---------------------------------------------------
import DevicesList from "../views/DevicesList.vue";
import DevicesCamera from "../views/DevicesCamera.vue";
import DevicesMap from "../views/DevicesMap.vue";
//---------------------------------------------------
import DeviceChart from "../views/DeviceChart.vue";
import DeviceImage from "../views/DeviceImage.vue";
import Playback from "../views/Playback.vue";
import Settings from "../views/Settings.vue";
import Favorites from "../views/Favorites.vue";
import ChartSetting from "../views/ChartSetting.vue";
import Manual from "../views/Manual.vue";
import LineNotify from "../views/LineNotify.vue";
import LineworksNotify from "../views/LineworksNotify.vue";
import MailNotify from "../views/MailNotify.vue";
//---------------------------------------------------

Vue.use(VueRouter);

const routes = [
    {
        name: "Login",
        path: "/login",
        meta: { isPublic: true },
        component: Login,
    },
    {
        name: "Logout",
        path: "/logout",
        meta: { isPublic: true },
        component: Logout,
    },
    // {
    //     path: "/home",
    //     name: "Home",
    //     component: Home,
    // },
    {
        path: "/",
        name: "DevicesMap",
        component: DevicesMap,
    },
    {
        name: "DevicesList",
        path: "/devices-list",
        component: DevicesList,
    },
    {
        name: "DevicesCamera",
        path: "/devices-camera",
        component: DevicesCamera,
    },
    {
        name: "DevicesMap",
        path: "/devices-map",
        component: DevicesMap,
    },
    {
        name: "DeviceChart",
        path: "/device-chart/:device_id/",
        component: DeviceChart,
    },
    {
        name: "DeviceImage",
        path: "/device-image/:device_id",
        component: DeviceImage,
    },
    {
        name: "Playback",
        path: "/playback/:device_id",
        component: Playback,
    },
    {
        name: "Settings",
        path: "/settings",
        component: Settings,
    },
    {
        name: "Favorites",
        path: "/favorites",
        component: Favorites,
    },
    {
        name: "ChartSetting",
        path: "/chart-setting",
        component: ChartSetting,
    },
    {
        name: "Manual",
        path: "/manual",
        component: Manual,
    },
    {
        name: "LineNotify",
        path: "/line-notify",
        component: LineNotify,
    },
    {
        name: "LineworksNotify",
        path: "/lineworks-notify",
        component: LineworksNotify,
    },
    {
        name: "MailNotify",
        path: "/mail-notify",
        component: MailNotify,
    },
    // {
    //     path: "/about",
    //     name: "About",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
    // },
];

const router = new VueRouter({
    routes,
});

export default router;
